const messages = {
	common: {
		close: '关闭',
		required: '必输',
		back: '返回',
		backToPlanList: '返回价格一览',
		backToPlanDetail: '返回计划详情',
		nightsCount: '{nights}晚',
		night: '第{nights}晚',
		day: '第{nights}天',
		dayuse: 'DayUse',
		checkIn: '入住',
		checkOut: '退房',
		lastCheckIn: '最后入住',
		breakFast: '早餐',
		lunch: '午餐',
		dinner: '晚餐',
		privateRoom: '包间',
		ownRoom: '房间',
		atRestaurant: '餐厅',
		restaurant: '餐厅',
		smoking: '吸烟',
		noSmoking: '禁烟',
		planNotFound: '没有可用的价格。\n请更换日期后再查询。',
		reservationInput: '预订输入',
		reservationConfirm: '预订确认通知',
		reservationComplete: '预订完成',
	},
	components: {
		frameMain: {
			checkReservation: '确认订单内容',
			reservationCheckExp: '请输入订单号和预定时输入的邮箱地址。',
			email: '邮箱',
			code: '订单号',
			canNotFindBooking: '未找到符合该内容的订单。',
			checkInDate: '来店日期',
			nights: '房晚',
			person: '人数',
			personCount: '成人{totalAdults}人、儿童{totalChildren}人',
			room: '房数',
			roomCount: '{roomCount}间',
			changeCondition: '修改住宿条件',
		},
		hotelInfo: {
			facilityInfo: '设施信息',
			hotelInfo: '酒店信息',
			address: '地址',
			tel: '电话号码',
			fax: 'FAX',
			checkInTime: '入住时间',
			checkOutTime: '退房时间',
			totalRooms: '总房数',
			officialHP: '官网',
			reservationEmail: '预定专用Email',
			facilities: '设施设备',
			staynaviId: 'STAYNAVI ID',
		},
	},
	plugins: {
		validation: {
			required: '此项为必输。',
			invalidEmail: '邮箱地址不正确。',
			onlyNumericArrowed: '仅允许输入数字。',
			arrowed6_10Alphanumeric: '请输入6字以上、10字以下的英文或数字。',
			max19Digits: '最大可输入19行。',
			arrowedUpperCase: '请输入大写英文。',
			maxLength: '请输入{max}字以下的',
			expirationMonth: '有效月份不正确',
			expirationDate: '请输入5个单字节字符、包括斜线 (/)。',
		},
	},
	booking: {
		bookingOption: {
			totalPrice: '宿泊金额',
			accomodationFeeTotal: '宿泊总计',
			serveiceTax: '服务费',
			total: '合计',
			totalWithoutConsumptionTax: '不包括税务 合计',
			totalWithoutConsumptionNServiceTax: '不包括税务 合计',
			aboutAccomodationTax: '关于宿泊税',
			showDetail: '详情请参照此处。',
			aboutHotSpringTax: '关于入汤税',
		},
		bookingRoomInfo: {
			selectedPlan: '选择价格',
			planId: '价格ID：',
			fromTo: '～',
			person: '名',
			bed: '床',
			guest: '宿泊预定数',
			roomNum: '第{count}间房',
			adultNum: '成人{count}名',
			childNum: '儿童{count}名',
			childlenNum: '儿童{count}名',
			childSetting: '儿童配置确认',
			child: '儿童',
			type: '类型: ',
		},
		privacyModal: {
			parivacyPolicy: '保密政策',
		},
	},
	plans: {
		plan: {
			planId: '价格ID: ',
			noFeeCancel: '在{date} 23:59之前可免费取消',
			planDetail: '价格详情',
			showAllPlan: '展示全部房型',
			hide: '仅展示上位',
			optionNotes:
        '※如果预定时需要选择OPTION，可以在输入宾客信息时进行选择。 如果您所选的OPTION产品仅支持预付、那么该订单整体的费用都需要进行预付，请知晓。',
			optionAvailable: '有OPTION',
			option: 'OPTION',
			discountAvailable: '有连住折扣',
			discountPrice: '折扣金额',
			applyDiscount: '折扣适用',
		},
		room: {
			max: '定员',
			person: '名',
			night: '晚',
			nights: '晚',
			roomTotal: '房间合计',
			perAdult: '每成人',
			roomNum: '第{num}间房',
			perRoom: '每房',
			discountOnBasePrice: '基于通常价格的折扣金额',
			detail: '客房详情',
			reserve: '订单',
		},
		roomType: {
			bed: '床',
			roomTypeDetail: '房间设备',
			bathAmenities: '洗浴用品',
			bedding: '寝具',
			aboutSmoking: '关于吸烟',
		},
		searchPanel: {
			specialCode: '特殊code',
			promotionCode: '优惠码',
			nights: '晚数',
			selectCheckInDate: '请从日历选择入住日期。',
			available: '有可用房',
			fewLeft: '仅少量可用房',
			notAvailable: '无可用房',
			search: '查询',
			changeCondition: '条件変更',
		},
		searchPanelRoomMapSelector: {
			personRoom: '人数 / 房数',
			adultChildRoomNum:
        '大成人{totalAdults}人、儿童{totalChildren}人、{totalRoom}房',
			adultRoomNum: '成人{totalAdults}人、{totalRoom}房',
			searchOptions: '人数选择',
			room: '房间',
			adultNum: '成人人数',
			childNum: '儿童人数',
			enterChildAge: '请选择儿童类型',
			childRank: '儿童{rank}类型',
			aged: '',
			fromTo: '～',
			age: '岁',
			addRoom: '房间追加',
			search: '查询',
			changeCondition: '条件変更',
		},
		toolBar: {
			numberOfPlans: '价格件数',
			plansNum: '件',
			filter: '筛选',
			filterConditions: '筛选',
			advancedFilter: '筛选',
			clear: '清空条件',
			breakFast: '含早餐',
			lunch: '含午餐',
			dinner: '含晚餐',
			meal: '一顿饭',
			planGroup: '特征',
			roomCategory: '房间类别',
		},
	},
	linkExpired: {
		linkExpired: '预定URL有效期已过期。请您重新指定日期进行预定手续。',
	},
	page403: {
		soldOut: '十分抱歉，您正在操作的此价格、此房型已经售罄，预定失败。',
		pleaseSearchOther: '十分抱歉，请您指定其他的日期和价格再次进行查询。',
		deletedPrivacyInfo: '您输入的个人信息已删除。',
	},
	page404: {
		pageNotFound: '没找到页面。',
	},
	page510: {
		errorMessage: 'Something went wrong, please try again later',
	},
	portal: {
		area: '区域',
		area2: '中区域',
		area3: '小区域',
		areaSelect: '请选择区域',
		facilityName: '酒店名称',
		facilityNameSearch: '酒店名称（可检索）',
		facilityType: '酒店类型',
		facilityList: '酒店一览',
		isReferencePrice: '※显示的价格范围为参考价格。 请查看各设施预订网站的详细信息。',
		hotel: '酒店',
		room: '房数',
		decide: '决定',
		lengthOfStay: '住宿区间',
		priceRange: 'JPY{min}-',
		nightCount: '{nights}晚',
		nightsCount: '{nights}晚',
		adult: '成人',
		child: '儿童',
		child2: '儿童',
		pax: '名',
		noSelect: '不指定',
		noDataFound: '未找到',
		promotionCode: '促销代码',
		conditions: '偏好条件',
		noPlansFound: '该价格不存在',
		childNotice: '该价格的定员人数里不包含儿童人数、参考价格里也不包含儿童费用。\n请到各酒店的预定网站查询各个儿童类型的费用详情。',
	},
};

export default messages;
