import Vue from 'vue';
import {Amplify} from 'aws-amplify';
import VueI18n from 'vue-i18n';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify.js';
import awsconfig from './aws-exports.js';
import MainPlugin from '@/plugins/Main.js';
import FramePlugin from '@/plugins/Frame.js';
import ValidationPlugin from '@/plugins/Validations.js';
import MainMixin from '@/mixins/Main.js';
import FrameMixin from '@/mixins/Frame.js';
import {ja, en, ko, zh_CN, zh_TW} from '@/locales';

window.dayjs = dayjs;

Amplify.configure(awsconfig);

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Tokyo');

Vue.config.productionTip = false;
Vue.use(MainPlugin);
Vue.use(FramePlugin);
Vue.use(ValidationPlugin);
Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: 'ja',
	messages: {ja, en, ko, zh_CN, zh_TW},
});

new Vue({
	mixins: [
		MainMixin,
		FrameMixin,
	],
	router,
	store,
	vuetify,
	i18n,
	render: h => h(App),
}).$mount('#app');
