const messages = {
	common: {
		close: '닫기',
		required: '필수',
		back: '뒤로',
		backToPlanList: '플랜 목록으로 돌아가기',
		backToPlanDetail: '플랜 세부사항으로 돌아가기',
		nightsCount: '{nights}박',
		night: '{nights}박',
		day: '{nights}일차',
		dayuse: '데이유즈',
		checkIn: '체크인',
		checkOut: '체크아웃',
		lastCheckIn: '최종 체크인',
		breakFast: '조식',
		lunch: '중식',
		dinner: '석식',
		privateRoom: '개인실',
		ownRoom: '객실',
		atRestaurant: '레스토랑',
		restaurant: '레스토랑',
		smoking: '흡연',
		noSmoking: '금연',
		planNotFound:
      '예약할 수 있는 플랜이 없습니다. \n번거로우시겠지만, 날짜를 변경해서 검색해 주세요.',
		reservationInput: '예약 입력',
		reservationConfirm: '예약 확인',
		reservationComplete: '예약 완료',
	},
	components: {
		frameMain: {
			checkReservation: '예약 내용 확인',
			reservationCheckExp:
        '예약번호와 예약할 때 등록한 이메일을 입력해 주세요.',
			email: '이메일',
			code: '예약번호',
			canNotFindBooking: '해당하는 예약 내용이 없습니다.',
			checkInDate: '체크인',
			nights: '숙박 일수',
			person: '인원수',
			personCount: '성인 {totalAdults}人, 아동 {totalChildren}人',
			room: '객실',
			roomCount: '{roomCount}개',
			changeCondition: '숙박 조건 변경',
		},
		hotelInfo: {
			facilityInfo: '시설 정보',
			hotelInfo: '호텔 정보',
			address: '주소',
			tel: '전화번호',
			fax: 'FAX',
			checkInTime: '체크인 시간',
			checkOutTime: '체크아웃 시간',
			totalRooms: '객실 총수',
			officialHP: '공식 HP',
			reservationEmail: '예약 전용 이메일',
			facilities: '시설 설비',
			staynaviId: 'STAYNAVI ID',
		},
	},
	plugins: {
		validation: {
			required: '이 항목은 필수입니다.',
			invalidEmail: '올바른 이메일 형식이 아닙니다.',
			onlyNumericArrowed: '숫자만 입력해 주세요.',
			arrowed6_10Alphanumeric:
        '6문자 이상, 10문자 이하의 영숫자로 입력해 주세요.',
			max19Digits: '최대 19자입니다.',
			arrowedUpperCase: '대문자 영어로 입력해 주세요.',
			maxLength: '반각 {max}자 이하로 입력하세요.',
			minLength: '반각 {min}자 이상으로 입력하세요.',
			expirationMonth: '유효 월이 올바르지 않습니다.',
			expirationDate: '슬래시(/)를 포함하여 반각 5자로 입력하세요.',
		},
	},
	booking: {
		bookingOption: {
			totalPrice: '숙박 요금',
			accomodationFeeTotal: '숙박 총 합계',
			serveiceTax: '서비스 요금',
			total: '합계',
			totalWithoutConsumptionTax: '세금별 합계',
			totalWithoutConsumptionNServiceTax: '세금 서비스료별 합계',
			aboutAccomodationTax: '숙박세에 대해서',
			showDetail: '상세정보는 이쪽을 참고해주세요.',
			aboutHotSpringTax: '입욕세에 대해서',
		},
		bookingRoomInfo: {
			selectedPlan: '선택한 플랜',
			planId: '플랜 ID：',
			fromTo: '～',
			person: '명',
			bed: '침대',
			guest: '숙박 예정 수',
			roomNum: '{count}번째 객실',
			adultNum: '성인 {count}명',
			childNum: '아동 {count}명',
			childlenNum: '아동 {count}명',
			childSetting: '아동 설정 확인',
			child: '아동',
			type: '타입',
		},
		privacyModal: {
			parivacyPolicy: '개인정보 보호 방침',
		},
	},
	plans: {
		plan: {
			planId: '플랜 IDz: ',
			noFeeCancel: '{date} 23시59분까지 무료 취소',
			planDetail: '플랜 상세정보',
			showAllPlan: '모든 객실 타입을 표시',
			hide: '상위만 표시',
			optionNotes:
        '※옵션 예약은 숙박플랜 예약 시 고객님 정보 입력 시 선택하실 수 있습니다. 예약하신 옵션 상품이 사전 카드 결제만인 경우, 숙박 예약 전체의 지불이 사전 신용카드 결제만 되는 것이니 양해 부탁드립니다.',
			optionAvailable: '옵션 있음',
			option: '옵션',
			discountAvailable: '연박할인 있음',
			discountPrice: '할인 금액',
			applyDiscount: '할인 적용',
		},
		room: {
			max: '정원',
			person: '명',
			night: '박',
			nights: '박',
			roomTotal: '총 객실',
			perAdult: '성인 1명당',
			roomNum: '{count}번째 객실',
			perRoom: '1객실당',
			discountOnBasePrice: '일반 가격에서의 할인 금액',
			detail: '객실 상세정보',
			reserve: '예약',
		},
		roomType: {
			bed: '침대',
			roomTypeDetail: '객실 설비',
			bathAmenities: '욕실 어메니티',
			bedding: '침구',
			aboutSmoking: '흡연에 대해서',
		},
		searchPanel: {
			specialCode: '스페셜 코드',
			promotionCode: '프로모션 코드',
			nights: '숙박 일수',
			selectCheckInDate: '체크인 날짜를 달력에서 선택해 주세요.',
			available: '예약 가능',
			fewLeft: '매진 임박',
			notAvailable: '매진',
			search: '검색',
			changeCondition: '조건변경',
		},
		searchPanelRoomMapSelector: {
			personRoom: '인원수 / 객실 수',
			adultChildRoomNum:
        '성인{totalAdults}명,아동{totalChildren}명,객실{totalRoom}개',
			adultRoomNum: '성인 {totalAdults}명, 객실 {totalRoom}개',
			searchOptions: '인원수 선택',
			room: '객실',
			adultNum: '성인 인원수',
			childNum: '아동 인원수',
			enterChildAge: '어린이의 아동 타입을 선택해 주세요.',
			childRank: '아동 {rank}타입',
			aged: '',
			fromTo: '～',
			age: '살',
			addRoom: '객실 추가',
			search: '검색',
			changeCondition: '조건변경',
		},
		toolBar: {
			numberOfPlans: '플랜 건수',
			plansNum: '건',
			filter: '검색',
			filterConditions: '검색',
			advancedFilter: '검색',
			clear: '검색 조건 초기화',
			breakFast: '조식 포함',
			lunch: '중식 포함',
			dinner: '석식 포함',
			meal: '식사',
			planGroup: '플랜 그룹',
			roomCategory: '객실 카테고리',
		},
	},
	linkExpired: {
		linkExpired:
      '예약 URL이 만료되었습니다. 정말로 죄송합니다만, 다시 날짜를 지정하여 예약을 부탁드립니다.',
	},
	page403: {
		soldOut:
      '정말로 죄송합니다만, 고객님의 예약 진행 중에 본 플랜, 본 객실이 매진 되었기 때문에 예약을 할 수 없었습니다.',
		pleaseSearchOther:
      '대단히 죄송합니다만, 다른 일정 또는 다른 플랜 등을 검색해 주시도록 부탁드립니다.',
		deletedPrivacyInfo: '입력해 주신 개인정보는 삭제했습니다.',
	},
	page404: {
		pageNotFound: '페이지를 찾을 수 없습니다.',
	},
	page510: {
		errorMessage: 'Something went wrong, please try again later',
	},
	portal: {
		area: '지역',
		area2: '중간지역',
		area3: '작은지역',
		areaSelect: '지역을 선택하세요',
		facilityName: '시설명',
		facilityNameSearch: '시설명（검색가능）',
		facilityType: '시설타입',
		facilityList: '시설목록',
		isReferencePrice: '※표시된 가격대는 참고 가격입니다. 각 시설 예약 사이트에서 자세한 내용을 확인하십시오.',
		hotel: '호텔',
		room: '객실',
		decide: '결정하다',
		lengthOfStay: '숙박기간',
		priceRange: 'JPY{min}-',
		nightCount: '{nights}박',
		nightsCount: '{nights}박',
		adult: '성인',
		child: '아동',
		child2: '아동',
		pax: '명',
		noSelect: '지정하지 않음',
		noDataFound: '후보가 발견되지 않습니다',
		promotionCode: '프로모션 코드',
		conditions: '검색조건',
		noPlansFound: '해당하는 플랜이 없습니다',
		childNotice:
      '표시된 플랜의 정원에 아동은 카운트되지 않고, 참고 가격에 아동 요금은 포함되어 있지 않습니다.\n각 시설 예약 사이트에서 아동 유형을 선택하고 자세한 내용을 확인하십시오.',
	},
};

export default messages;
