import {API} from 'aws-amplify';
import awsExports from '@/aws-exports.js';
const endPoint = process.env.VUE_APP_PORTAL_ENDPOINT;

export class PortalSite {
	constructor(data) {
		Object.assign(this, data);
	}

	static async get(id) {
		API.configure({
			...awsExports,
			aws_cloud_logic_custom: [
				...awsExports.aws_cloud_logic_custom,
				{
					name: 'PortalRestApi',
					endpoint: endPoint,
					region: 'ap-northeast-1',
				},
			],
		});
		const data = await API.get('PortalRestApi', `/unauth/portal/${id}/portal-sites/${id}`);
		return data;
	}
}

