const messages = {
	common: {
		close: '閉じる',
		back: '戻る',
		required: '必須',
		backToPlanList: 'プラン一覧に戻る',
		backToPlanDetail: 'プラン詳細に戻る',
		nightsCount: '{nights}泊',
		night: '{nights}泊目',
		day: '{nights}日目',
		dayuse: 'デイユース',
		checkIn: 'チェックイン',
		checkOut: 'チェックアウト',
		lastCheckIn: '最終チェックイン',
		breakFast: '朝食',
		lunch: 'ランチ',
		dinner: '夕食',
		privateRoom: '個室',
		ownRoom: 'お部屋',
		atRestaurant: 'レストラン',
		restaurant: 'レストラン',
		smoking: '喫煙可',
		noSmoking: '禁煙',
		planNotFound:
      '予約可能なプランがありませんでした。\nお手数ですが日付を変更して検索してください。',
		reservationInput: '予約入力',
		reservationConfirm: '予約確認',
		reservationComplete: '予約完了',
	},
	components: {
		frameMain: {
			checkReservation: '予約内容確認',
			reservationCheckExp:
        '予約番号と予約時にご登録いただいたEメールアドレスを入力してください。',
			email: 'メールアドレス',
			code: '予約番号',
			canNotFindBooking: '該当の予約内容が見つかりませんでした。',
			checkInDate: 'チェックイン日',
			nights: '泊数',
			person: '人数',
			personCount: '大人{totalAdults}人、小人{totalChildren}人',
			room: '部屋数',
			roomCount: '{roomCount}室',
			changeCondition: '宿泊条件変更',
		},
		hotelInfo: {
			facilityInfo: '施設情報',
			hotelInfo: 'ホテル情報',
			address: '住所',
			tel: '電話番号',
			fax: 'FAX',
			checkInTime: 'チェックイン時間',
			checkOutTime: 'チェックアウト時間',
			totalRooms: '総客室数',
			officialHP: '公式HP',
			reservationEmail: '予約専用Email',
			facilities: '施設設備',
			staynaviId: 'STAYNAVI ID',
		},
	},
	plugins: {
		validation: {
			required: 'この項目は必須です。',
			invalidEmail: 'メールアドレスが不正です。',
			onlyNumericArrowed: '数字のみで入力してください。',
			arrowed6_10Alphanumeric:
        '6文字以上、10文字以下の英数字で入力してください。',
			max19Digits: '最大19桁です。',
			arrowedUpperCase: '半角英大文字で入力してください。',
			maxLength: '半角{max}文字以下で入力してください。',
			minLength: '半角{min}文字以上で入力してください。',
			expirationMonth: '有効月が正しくありません',
			expirationDate: 'スラッシュ(/)を含め半角5文字で入力してください',
			katakana: 'カタカナで入力してください',
		},
	},
	booking: {
		bookingOption: {
			totalPrice: '宿泊金額',
			accomodationFeeTotal: '宿泊料合計',
			serveiceTax: 'サービス料',
			total: '合計',
			totalWithoutConsumptionTax: '税別合計',
			totalWithoutConsumptionNServiceTax: '税サ別合計',
			aboutAccomodationTax: '宿泊税について',
			showDetail: '詳細はこちらをご参照ください',
			aboutHotSpringTax: '入湯税について',
		},
		bookingRoomInfo: {
			selectedPlan: '選択プラン',
			planId: 'プランID：',
			fromTo: '～',
			person: '名',
			bed: 'ベッド',
			guest: '宿泊予定数',
			roomNum: '{count}部屋目',
			adultNum: '大人{count}名',
			childNum: '小人{count}名',
			childlenNum: '小人{count}名',
			childSetting: '小人設定確認',
			child: '小人',
			type: 'タイプ: ',
		},
		hotelContact: {
			contactEmailForReservation: 'ご予約連絡窓口メールアドレス',
			hotelUrl: 'ホテルURL',
		},
		privacyModal: {
			parivacyPolicy: 'プライバシーポリシー',
		},
	},
	plans: {
		plan: {
			planId: 'プランID: ',
			noFeeCancel: '{date} 23時59分までは、キャンセル料無料',
			planDetail: 'プラン詳細',
			showAllPlan: 'すべての客室タイプを表示',
			hide: '上位のみ表示',
			optionNotes:
        '※オプションのご予約は、宿泊プラン予約時のお客様情報ご入力の際にご選択いただけます。 予約したオプション商品が事前クレジットカード決済のみの場合、ご宿泊予約全体のお支払いが事前クレジットカード決済のみとなりますこと、ご了承ください。',
			optionAvailable: 'オプションあり',
			option: 'オプション',
			discountAvailable: '連泊割引あり',
			discountPrice: '割引額',
			applyDiscount: '割引適用',
		},
		room: {
			max: '定員',
			person: '名',
			night: '泊',
			nights: '泊',
			roomTotal: '室合計',
			perAdult: '大人1名あたり',
			roomNum: '{num}部屋目',
			perRoom: '1室あたり',
			discountOnBasePrice: '通常価格からの割引額',
			detail: '客室詳細',
			reserve: 'ご予約',
		},
		roomType: {
			bed: 'ベッド',
			roomTypeDetail: '客室設備',
			bathAmenities: 'バスアメニティ',
			bedding: '寝具',
			aboutSmoking: '喫煙について',
		},
		searchPanel: {
			specialCode: 'スペシャルコード',
			promotionCode: 'プロモーションコード',
			nights: '泊数',
			selectCheckInDate: 'チェックイン日をカレンダーから選んで下さい。',
			available: '空室あり',
			fewLeft: '空室残りわずか',
			notAvailable: '空室なし',
			search: '検索',
			changeCondition: '条件変更',
		},
		searchPanelRoomMapSelector: {
			personRoom: '人数 / 部屋数',
			adultChildRoomNum:
        '大人{totalAdults}人、小人{totalChildren}人、{totalRoom}室',
			adultRoomNum: '大人{totalAdults}人、{totalRoom}室',
			searchOptions: '人数選択',
			room: '部屋',
			adultNum: '大人人数',
			childNum: '小人人数',
			enterChildAge: 'お子様の小人タイプを選択してください',
			childRank: '小人{rank}タイプ',
			aged: '',
			fromTo: '～',
			age: '歳',
			addRoom: '部屋追加',
			search: '検索',
			changeCondition: '条件変更',
		},
		toolBar: {
			numberOfPlans: 'プラン件数',
			plansNum: '件',
			filter: '絞り込み',
			filterConditions: '絞り込み条件',
			advancedFilter: '絞り込み',
			clear: '条件をクリア',
			breakFast: '朝食付き',
			lunch: '昼食付き',
			dinner: '夕食付き',
			meal: '食事',
			planGroup: 'プラングループ',
			roomCategory: '客室カテゴリー',
		},
	},
	linkExpired: {
		linkExpired:
      'ご予約URLの有効期限が切れています。誠におそれいりますが、再度日付をご指定いただき、ご予約手続きをお願いいたします。',
	},
	page403: {
		soldOut:
      '誠に申し訳ございませんが、お客様のご予約操作中に本プラン、本お部屋タイプが完売したため、ご予約をお受けすることができませんでした。',
		pleaseSearchOther:
      '大変恐れ入りますが、再度、別の日程または別のプラン等を検索していただきますようお願いいたします。',
		deletedPrivacyInfo: 'ご入力いただきました個人情報は、消去いたしました。',
	},
	page404: {
		pageNotFound: 'ページが見つかりませんでした。',
	},
	page510: {
		errorMessage:
      'エラーが発生しました。大変恐れ入りますが、再度お試しください。',
	},
	portal: {
		area: 'エリア',
		area2: '中エリア',
		area3: '小エリア',
		areaSelect: 'エリアを選択してください',
		facilityName: '施設名',
		facilityNameSearch: '施設名（検索可）',
		facilityType: '施設タイプ',
		facilityList: '施設一覧',
		isReferencePrice: '※表示されている価格帯は参考価格です。各施設予約サイトにて詳細をご確認ください。',
		hotel: '施設',
		room: '客室',
		decide: '決定する',
		lengthOfStay: '宿泊期間',
		priceRange: '{min}円〜',
		nightCount: '{nights}泊',
		nightsCount: '{nights}泊',
		adult: '大人',
		child: '小人',
		child2: '子供',
		pax: '名',
		noSelect: '指定しない',
		noDataFound: '候補が見つかりません',
		promotionCode: 'プロモーションコード',
		conditions: 'こだわり条件',
		noPlansFound: '検索日程で該当するプランがありません',
		childNotice: '表示されているプランの定員に小人はカウントされておらず、参考価格に小人料金は含まれておりません。\n各施設予約サイトにて小人タイプを選択のうえ、詳細をご確認ください。',
	},
};

export default messages;
