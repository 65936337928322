export default {
  install(vue) {
    vue.mixin({
      methods: {
        modalCheck: function(name) {
          return this.$root.frame.modal == name
        },
        modalShow: function(name) {
          this.$root.frame.modal = name
        },
        modalClose() {
          this.$root.frame.modal = ""
          this.$emit("modal-close")
        },
      },
    })
  },
}
