import {API} from 'aws-amplify';
import awsExports from '@/aws-exports.js';

const endPoint = process.env.VUE_APP_PORTAL_ENDPOINT;
API.configure({
	...awsExports,
	aws_cloud_logic_custom: [
		...awsExports.aws_cloud_logic_custom,
		{
			name: 'PortalRestApi',
			endpoint: endPoint,
			region: 'ap-northeast-1',
		},
	],
});

export class PortalHotel {
	constructor(data) {
		Object.assign(this, data);
	}

	static async index(portalId, query) {
		const data = await API.get('PortalRestApi', `/unauth/portal/${portalId}/hotels`, {queryStringParameters: query});
		return data;
	}
}

