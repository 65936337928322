export default {
  install(vue) {
    console.log('Installing validation plugin..')
    vue.mixin({
      computed: {
        formRules: {
          get() {
            return {
              required: (v) => !!v || this.$t('plugins.validation.required'),
              email: (v) =>
                /.+@.+\..+/.test(v) ||
                this.$t('plugins.validation.invalidEmail'),
              phone: (v) =>
                /\d+/.test(v) ||
                this.$t('plugins.validation.onlyNumericArrowed'),
              promotionCode: (v) =>
                (v !== null && /^[A-Za-z0-9]{6,10}$/.test(v)) ||
                this.$t('plugins.validation.arrowed6_10Alphanumeric'),
              //ハイフンを含むかで変わってくる
              maxlength: (v) =>
                (v && v.length <= 19) ||
                this.$t('plugins.validation.max19Digits'),
              //半角アルファベット（半角英大文字）
              alpha_upper: (v) =>
                (v !== null && /^[A-Z ]+$/.test(v) ||
                this.$t('plugins.validation.arrowedUpperCase')),
            }
          },
        },
      },
    })
  },
}
