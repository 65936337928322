import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: '/portal-site/:id',
    name: "PortalSite",
    component: () => import(/* webpackChunkName: "info" */ "../views/PortalSite.vue"),
  },
  // モックは営業で使用します。残してください。
  {
    path: '/portal-site-mock',
    component: () => import(/* webpackChunkName: "info" */ "../views/PortalSiteMock.vue"),
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "info" */ "../views/Page404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
