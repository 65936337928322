const messages = {
	common: {
		close: '關閉',
		back: '返回',
		required: '必輸',
		backToPlanList: '返回價格一覽',
		backToPlanDetail: '返回計劃詳情',
		nightsCount: '{nights}晚',
		night: '第{nights}晚',
		day: '第{nights}天',
		dayuse: 'DayUse',
		checkIn: 'CheckIn',
		checkOut: 'CheckOut',
		lastCheckIn: 'Last CheckOut',
		breakFast: '早餐',
		lunch: '午餐',
		dinner: '晚餐',
		privateRoom: '包間',
		ownRoom: '房間',
		atRestaurant: '餐廳',
		restaurant: '餐廳',
		smoking: '吸煙',
		noSmoking: '禁煙',
		planNotFound: '沒有可用的價格。\n請更換日期後再查詢。',
		reservationInput: '預訂輸入',
		reservationConfirm: '預訂確認通知',
		reservationComplete: '預訂完成',
	},
	components: {
		frameMain: {
			checkReservation: '確認訂單內容',
			reservationCheckExp: '請輸入訂單號和預定時輸入的郵箱地址。',
			email: 'Email',
			code: '訂單號',
			canNotFindBooking: '未找到符合該內容的訂單。',
			checkInDate: 'CheckIn日期',
			nights: '房晚',
			person: '人數',
			personCount: '成人{totalAdults}人、兒童{totalChildren}人',
			room: '房數',
			roomCount: '{roomCount}間房',
			changeCondition: '修改住宿條件',
		},
		hotelInfo: {
			facilityInfo: '設施信息',
			hotelInfo: '酒店信息',
			address: '地址',
			tel: '電話號碼',
			fax: 'FAX',
			checkInTime: 'CheckIn時間',
			checkOutTime: 'CheckOut時間',
			totalRooms: '总房數',
			officialHP: 'HomePage',
			reservationEmail: '預定專用Email',
			facilities: '設施設備',
			staynaviId: 'STAYNAVI ID',
		},
	},
	plugins: {
		validation: {
			required: '此項為必輸。',
			invalidEmail: 'Email不正確。',
			onlyNumericArrowed: '僅允許輸入數字。',
			arrowed6_10Alphanumeric: '請輸入6字以上、10字以下的英文或數字。',
			max19Digits: '最大可輸入19行。',
			arrowedUpperCase: '請輸入大寫英文。',
			maxLength: '請輸入至少{max}個單字節字符。',
			minLength: '請輸入至少{min}個單字節字符。',
			expirationMonth: '有效月份不正確',
			expirationDate: '請輸入5個單字節字符、包括斜線 (/)。',
		},
	},
	booking: {
		bookingOption: {
			totalPrice: '宿泊金額',
			accomodationFeeTotal: '宿泊總計',
			serveiceTax: '服務費用',
			total: '合計',
			totalWithoutConsumptionTax: '不包括稅務 合計',
			totalWithoutConsumptionNServiceTax: '不包括稅務服務費 合計',
			aboutAccomodationTax: '關於宿泊稅',
			showDetail: '詳情請參照此處。',
			aboutHotSpringTax: '關於入湯稅',
		},
		bookingRoomInfo: {
			selectedPlan: '選擇價格',
			planId: '價格ID：',
			fromTo: '～',
			person: '人',
			bed: '床',
			guest: '宿泊預定數',
			roomNum: '第{count}間房',
			adultNum: '成人{count}名',
			childNum: '兒童{count}名',
			childlenNum: '兒童{count}名',
			childSetting: '兒童配置确认',
			child: '兒童',
			type: '類型: ',
		},
		hotelContact: {
			contactEmailForReservation: '預定詢問Email',
			hotelUrl: '酒店URL',
		},
		privacyModal: {
			parivacyPolicy: '保密政策',
		},
	},
	plans: {
		plan: {
			planId: '價格ID: ',
			noFeeCancel: '{date} 23時59分之前可免费取消',
			planDetail: '價格詳情',
			showAllPlan: '展示全部房型',
			hide: '僅展示上位',
			optionNotes:
        '※如果預定時需要選擇OPTION，可以在輸入賓客信息時進行選擇。 如果您所選的OPTION產品僅支持預付、那麼該訂單整體的費用都需要進行預付，請知曉。',
			optionAvailable: '有OPTION',
			option: 'OPTION',
			discountAvailable: '有連住折扣',
			discountPrice: '折扣金額',
			applyDiscount: '折扣適用',
		},
		room: {
			max: '定員',
			person: '名',
			night: '晚',
			nights: '晚',
			roomTotal: '房間合計',
			perAdult: '每成人',
			roomNum: '第{num}間房',
			perRoom: '每房',
			discountOnBasePrice: '基於通常價格的折扣金額',
			detail: '客房詳情',
			reserve: '預定',
		},
		roomType: {
			bed: '床',
			roomTypeDetail: '房間设备',
			bathAmenities: '洗浴用品',
			bedding: '寢具',
			aboutSmoking: '关于吸煙',
		},
		searchPanel: {
			specialCode: '特殊code',
			promotionCode: '优惠码',
			nights: '晚',
			selectCheckInDate: '請從日曆選擇CheckIn日期。',
			available: '有可用房',
			fewLeft: '僅少量可用房',
			notAvailable: '無可用房',
			search: '查詢',
			changeCondition: '條件改變',
		},
		searchPanelRoomMapSelector: {
			personRoom: '人数 / 房數',
			adultChildRoomNum:
        '成人{totalAdults}人、兒童{totalChildren}人、{totalRoom}間',
			adultRoomNum: '成人{totalAdults}人、{totalRoom}間',
			searchOptions: '人數選擇',
			room: '房間',
			adultNum: '成人人數',
			childNum: '兒童人数',
			enterChildAge: '请选择兒童类型',
			childRank: '兒童{rank}类型',
			aged: '',
			fromTo: '～',
			age: '歳',
			addRoom: '房間追加',
			search: '查詢',
			changeCondition: '條件改變',
		},
		toolBar: {
			numberOfPlans: '價格件數',
			plansNum: '件',
			filter: '篩選',
			filterConditions: '篩選',
			advancedFilter: '篩選',
			clear: '清空條件',
			breakFast: '含早餐',
			lunch: '含中餐',
			dinner: '含晚餐',
			meal: '一頓飯',
			planGroup: '特徵',
			roomCategory: '房間類別',
		},
	},
	linkExpired: {
		linkExpired: '預定URL有效期已過期。請您重新指定日期進行預定手續。',
	},
	page403: {
		soldOut: '十分抱歉，您正在操作的此價格、此房型已經售罄，預定失敗。',
		pleaseSearchOther: '十分抱歉，請您指定其他的日期和價格再次進行查詢。',
		deletedPrivacyInfo: '您輸入的個人信息已刪除。',
	},
	page404: {
		pageNotFound: '沒有找到頁面。',
	},
	page510: {
		errorMessage: 'Something went wrong, please try again later',
	},
	portal: {
		area: '區域',
		area2: '中區域',
		area3: '小區域',
		areaSelect: '請選擇區域',
		facilityName: '酒店名稱',
		facilityNameSearch: '酒店名稱（可檢索）',
		facilityType: '酒店類型',
		facilityList: '酒店一覽',
		isReferencePrice: '※顯示的價格範圍為參考價格。 請查看各設施預訂網站的詳細信息。',
		hotel: '酒店',
		room: '房數',
		decide: '决定',
		lengthOfStay: '住宿日期',
		priceRange: 'JPY{min}-',
		nightCount: '{nights}晚',
		nightsCount: '{nights}晚',
		adult: '成人',
		child: '兒童',
		child2: '兒童',
		pax: '名',
		noSelect: '不指定',
		noDataFound: '未找到數據',
		promotionCode: '促销代碼',
		conditions: '偏好條件',
		noPlansFound: '該價格不存在',
		childNotice: '該價格的定員人數裏不包含兒童人數、參考價格裏也不包含兒童費用。 請到各飯店的預定網站査詢各個兒童類型的費用詳情。',
	},
};

export default messages;
