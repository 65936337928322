import {API} from 'aws-amplify';
import awsExports from '@/aws-exports.js';
const endPoint = process.env.VUE_APP_PORTAL_ENDPOINT;

export class SiteView {
	constructor(data) {
		Object.assign(this, data);
	}

	static async create(portalId, token) {
		API.configure({
			...awsExports,
			aws_cloud_logic_custom: [
				...awsExports.aws_cloud_logic_custom,
				{
					name: 'PortalRestApi',
					endpoint: endPoint,
					region: 'ap-northeast-1',
				},
			],
		});
		const data = await API.post('PortalRestApi', `/unauth/portal/${portalId}/site-view`, {body: {token}});
		return data;
	}
}

