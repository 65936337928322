import {PortalSite} from "@/models/index.js";

import dayjs from "dayjs";
import { cloneDeep } from "lodash";

export default {
  data() {
    return {
      viewLoading_: true,
      rootDataReady_: false,
      langs_: [],
      lang_: "",
      portalDefaultData: {},
      query_: {},

      hotelTypes_: [],
      hotelTags_: [],
      roomTags_: [],
    };
  },
  watch: {
    lang: "setI18nLocale",
    async "$route.params.id"(v) {
      if (!v) return;
      await this.getPortalDefaultSetting();
      await this.setLang();
      this.rootDataReady_ = true;
    },
    async '$route.path'(v) {
			if (v == '/') return;
			if (this.$route.path == '/portal-site-mock') {
        await Promise.all([
          this.setLangForMock(),
          this.setDefaultQueryForMock(),
        ]);
        this.rootDataReady_ = true;
      }
		},
  },
  methods: {
    async getPortalDefaultSetting() {
      const data = await PortalSite.get(this.portalSiteId);
      this.portalDefaultData = data
      this.langs_ = data.pageLangs.map((d) => ({
        value: d,
        label: this.getLangFullText(d),
      }));

      this.setDefaultQuery();
    },
    async setDefaultQuery() {
      const {OffsetDays, nights, paxByRoom, roomCount} = this.portalDefaultData.defaultCondition;
      let query = cloneDeep(this.$route.query);

      const today = dayjs().format("YYYY-MM-DD");
      const searchDay = OffsetDays || OffsetDays == 0 ? OffsetDays : 1;
      const searchNight = nights || nights == 0 ? nights : 1;
      const searchPaxPerRoom = paxByRoom;
      const searchRoom = roomCount;

      if (query.checkedInOn < today) {
        query.checkedInOn = dayjs(today).add(searchDay, "day").format("YYYY-MM-DD");
        query.checkedOutOn = dayjs(query.checkedInOn)
          .clone()
          .add(query.night || searchNight, "days")
          .format("YYYY-MM-DD");
        query.night = dayjs(query.checkedOutOn).diff(query.checkedInOn, "days");
      } else {
        if (!query.checkedInOn)
          query.checkedInOn = dayjs(today)
            .add(searchDay, "day")
            .format("YYYY-MM-DD");
        if (!query.checkedOutOn)
          query.checkedOutOn = dayjs(query.checkedInOn)
            .clone()
            .add(searchNight, "days")
            .format("YYYY-MM-DD");
        if (!query.night)
          query.night = dayjs(query.checkedOutOn).diff(query.checkedInOn, "days");
      }

      if (!query.roomMap) {
        const adults = Array.from({ length: searchPaxPerRoom })
          .map(() => "A")
          .join("-");
        query.roomMap = Array.from({ length: searchRoom })
          .map(() => adults)
          .join(",");
      }
      if (!query.near) query.near = "true";
      this.query_ = query;
      this.$router.replace({ query: query }, () => {});
    },
    // i18nの言語設定
    setI18nLocale() {
      this.$i18n.locale = this.lang;
    },
    setLang() {
      const currentLang =
        this.$route.query.lang || localStorage.getItem("lang");
      const existInApi = this.langs_.some((lang) => lang.value === currentLang);
      if (existInApi) this.lang = currentLang;
      else this.lang = "ja";
      this.setI18nLocale();
    },
    getLangFullText(lang) {
      switch (lang) {
        case "en":
          return "English";
        case "ko":
          return "한국어";
        case "zh_CN":
          return "简体中文";
        case "zh_TW":
          return "繁體中文";
        default:
          return "日本語";
      }
    },
    setLangForMock() {
      this.langs_ = [
        {
          value: "ja",
          label: this.getLangFullText("ja"),
        },
        {
          value: "en",
          label: this.getLangFullText("en"),
        },
      ];

      const currentLang =
        this.$route.query.lang || localStorage.getItem("lang");
      const existInApi = this.langs_.some((lang) => lang.value === currentLang);
      if (existInApi) this.lang = currentLang;
      else this.lang = "ja";
      this.setI18nLocale();
    },
    async setDefaultQueryForMock() {
      let query = cloneDeep(this.$route.query);

      if (!query.checkedInOn) query.checkedInOn = dayjs().format("YYYY-MM-DD");
      if (!query.checkedOutOn)
        query.checkedOutOn = dayjs(query.checkedInOn)
          .clone()
          .add(1, "days")
          .format("YYYY-MM-DD");
      if (!query.night)
        query.night = dayjs(query.checkedOutOn).diff(query.checkedInOn, "days");
      if (!query.roomMap) query.roomMap = "A-A";
      this.$router.replace({ query: query }, () => {});
    },
  },
};
