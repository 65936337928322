const messages = {
	common: {
		close: 'close',
		back: 'Back',
		required: 'Required',
		backToPlanList: 'Back to plan list',
		backToPlanDetail: 'Back to plan details',
		nightsCount: '{nights}',
		night: 'Night{nights}',
		day: 'Day{nights}',
		dayuse: 'DayUse',
		checkIn: 'Checkin',
		checkOut: 'Checkout',
		lastCheckIn: 'Last Checkin',
		breakFast: 'Breakfast',
		lunch: 'Lunch',
		dinner: 'Dinner',
		privateRoom: 'Private room',
		ownRoom: 'Own room',
		atRestaurant: 'Restaurant',
		restaurant: 'Restaurant',
		smoking: 'Smoking',
		noSmoking: 'Non-smoking',
		planNotFound:
      'There was no plan and room available for reservation. \nPlease change the date and search.',
		reservationInput: 'Input',
		reservationConfirm: 'Comfirn',
		reservationComplete: 'Complete',
	},
	components: {
		frameMain: {
			checkReservation: 'Check reservation',
			reservationCheckExp: 'Please enter booking Confirmation number and email',
			email: 'Email',
			code: 'Booking Confirmation number',
			canNotFindBooking: 'Reservation was not found.',
			checkInDate: 'CheckIn',
			nights: 'Nights',
			person: 'Person',
			personCount: '{totalAdults}ADLT, {totalChildren}CHD',
			room: 'Room',
			roomCount: '{roomCount}RM',
			changeCondition: 'Search Condition',
		},
		hotelInfo: {
			facilityInfo: 'Facility Info',
			hotelInfo: 'Hotel Profile',
			address: 'Address',
			tel: 'Tel',
			fax: 'FAX',
			checkInTime: 'Check-in Time',
			checkOutTime: 'Check-out Time',
			totalRooms: 'Total rooms',
			officialHP: 'Official HP',
			reservationEmail: 'Reservation-only Email',
			facilities: 'Facilities',
			staynaviId: 'STAYNAVI ID',
		},
	},
	plugins: {
		validation: {
			required: 'Required.',
			invalidEmail: 'Invalid Email.',
			onlyNumericArrowed: 'Only numeric allowed.',
			arrowed6_10Alphanumeric: 'Should be 6-10.',
			max19Digits: 'The maximum number of digits is 19.',
			arrowedUpperCase: 'Should be uppercase letter.',
			maxLength: 'Should be {max} characters or less',
			minLength: 'Should be {min} characters or more',
			expirationMonth: 'The valid month is incorrect',
			expirationDate: 'Should be 5 characters including slash(/)',
		},
	},
	booking: {
		bookingOption: {
			totalPrice: 'Total Price',
			accomodationFeeTotal: 'Accomodation fee total',
			serveiceTax: 'Service Tax',
			total: 'Total',
			totalWithoutConsumptionTax: 'Total(Excl.Tax)',
			totalWithoutConsumptionNServiceTax: 'Total(Excl.Tax&Service charge)',
			aboutAccomodationTax: 'Accomodation Tax',
			showDetail: 'Please refer here for detail information.',
			aboutHotSpringTax: 'Hotspring Tax',
		},
		bookingRoomInfo: {
			selectedPlan: 'Selected plan',
			planId: 'Plan ID:',
			fromTo: ' - ',
			person: 'person',
			bed: 'bed',
			guest: 'Guests',
			roomNum: 'Room {count}',
			adultNum: '{count} adult',
			childNum: '{count} child',
			childlenNum: '{count} childlen',
			childSetting: 'child setting',
			child: 'Child',
			type: 'Type: ',
		},
		hotelContact: {
			contactEmailForReservation: 'Contact email for reservation',
			hotelUrl: 'Official HP',
		},
		privacyModal: {
			parivacyPolicy: 'Privacy Policy',
		},
	},
	plans: {
		plan: {
			planId: 'Plan ID: ',
			noFeeCancel: 'No cancellation fee until {date}',
			planDetail: 'Plan detail',
			showAllPlan: 'Show all room types',
			hide: 'Hide',
			optionNotes: '',
			optionAvailable: 'Options available',
			option: 'Options',
			discountAvailable: 'Consecutive nights discount',
			discountPrice: 'discount price',
			applyDiscount: 'discount apply',
		},
		room: {
			max: 'Max',
			person: 'person',
			night: 'night',
			nights: 'nights',
			roomTotal: ' Subtotal',
			perAdult: 'Per adult',
			roomNum: 'Room {num}',
			perRoom: 'Per room',
			discountOnBasePrice: 'Discount on base price',
			detail: 'Detail',
			reserve: 'Reserve',
		},
		roomType: {
			bed: 'bed',
			roomTypeDetail: '客室設備',
			bathAmenities: 'Room type detail',
			bedding: 'Bath amenities',
			aboutSmoking: 'Smoking',
		},
		searchPanel: {
			specialCode: 'Special Code',
			promotionCode: 'Promotion Code',
			nights: 'nights',
			selectCheckInDate: 'Please select your check in date.',
			available: 'Available',
			fewLeft: 'Few left',
			notAvailable: 'Not available',
			search: 'Search',
			changeCondition: 'Search',
		},
		searchPanelRoomMapSelector: {
			personRoom: 'Person / Room',
			adultChildRoomNum: '{totalAdults}ADLT,{totalChildren}CHD,{totalRoom}RM',
			adultRoomNum: '{totalAdults}ADLT,{totalRoom}RM',
			searchOptions: 'Search options',
			room: 'Room',
			adultNum: 'Number of adults',
			childNum: 'Number of children',
			enterChildAge: 'Please enter child age.',
			childRank: 'Child{rank} Rank',
			aged: 'Aged',
			fromTo: '-',
			age: '',
			addRoom: 'Add Room',
			search: 'Search',
			changeCondition: 'Search',
		},
		toolBar: {
			numberOfPlans: 'Number of plans',
			plansNum: '',
			filter: 'Filter',
			filterConditions: 'Filter',
			advancedFilter: 'Advanced Filter',
			clear: 'Clear',
			breakFast: 'Breakfast',
			lunch: 'Lunch',
			dinner: 'Dinner',
			meal: 'Meal',
			planGroup: 'Plan Group',
			roomCategory: 'Room Category',
		},
	},
	linkExpired: {
		linkExpired:
      'Your reservation URL has expired. We apologize for the inconvenience, please specify the date again and make a reservation.',
	},
	page403: {
		soldOut: 'Sold out, You just missed it.',
		pleaseSearchOther:
      'We are unable to accept your reservation because selected plan and room are  sold out during reservation process.',
		deletedPrivacyInfo:
      'We apologize for the inconvenience, please choose other plan and try again.We have not saved your personal information',
	},
	page404: {
		pageNotFound: 'Page Not Found.',
	},
	page510: {
		errorMessage: 'Something went wrong, please try again later',
	},
	portal: {
		area: 'Area',
		area2: 'Medium Area',
		area3: 'Small Area',
		areaSelect: 'Select area',
		facilityName: 'Accommodation',
		facilityNameSearch: 'Accommodation(Searchable)',
		facilityType: 'Accommodation Type',
		facilityList: 'Accommodation List',
		isReferencePrice: '*The displayed price range is a reference price. Please check the details on each facility reservation site.',
		hotel: 'Accommodation',
		room: 'Room',
		decide: 'Decide',
		lengthOfStay: 'Length of stay',
		priceRange: 'JPY{min}-',
		nightCount: '{nights} N',
		nightsCount: '{nights} N',
		adult: 'Adult',
		child: 'Child',
		child2: 'Child',
		pax: 'person',
		noSelect: 'No Select',
		noDataFound: 'No data available',
		promotionCode: 'Promition Code',
		conditions: 'Conditions',
		noPlansFound: 'There are no applicable plans',
		childNotice:
      'Children are not counted in the capacity of the displayed plan, and the reference price does not include the child fee.\nPlease select the child type on each facility reservation site and check the details.',
	},
};

export default messages;
