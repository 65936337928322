<template>
	<v-app>
		<v-progress-circular
			v-if="viewLoading || !rootDataReady"
			class="my-auto mx-auto"
			:width="3"
			color="primary"
			indeterminate></v-progress-circular>
		<router-view
			v-show="!viewLoading && rootDataReady"
			:portalHotels="portalHotels"
			:areas="areas"
			:areasAll="areasAll"
			:hotelTags="hotelTags"
			:hotelTypes="hotelTypes"
			:roomTags="roomTags"
			:isThirdLayerSet="isThirdLayerSet"
			:key="$route.fullPath" />
	</v-app>
</template>

<script>
import {FilterItem, SiteView} from '@/models/index.js';
import {
	mockHotelTypes,
	mockHotelTags,
	mockRoomTags,
	mockPortalHotels,
	mockAreas,
	mockAreasAll,
} from './mockData/data.json';

export default {
	name: 'App',
	watch: {
		async '$route.params.id'(v) {
			if (!v) return;
			await this.getData();
			this.viewLoading = false;
		},
		async '$route.path'(v) {
			if (v == '/') return;
			if (this.$route.path == '/portal-site-mock') this.setDataForMock();
		},
	},
	data() {
		return {
			portalHotels: [],
			areas: [],
			areasAll: [],
			hotelTags: [],
			hotelTypes: [],
			roomTags: [],
			isThirdLayerSet: false,
		};
	},
	methods: {
		async getData() {
			await Promise.all([this.putSiteView(), this.getFilterItem()]);
		},
		async putSiteView() {
			const isViewTokenString =
				typeof this.viewToken === 'string' || this.viewToken instanceof String;
			const token = isViewTokenString ? this.viewToken : '';
			const data = await SiteView.create(this.portalSiteId, token);
			if (!data) return;

			if (this.viewToken != data) {
				let viewToken = JSON.parse(localStorage.getItem('viewToken') || '{}');
				if (!this.portalSiteId) return;
				viewToken[this.portalSiteId] = data;
				localStorage.setItem('viewToken', JSON.stringify(viewToken));
			}
		},
		async getFilterItem() {
			const data = await FilterItem.index(this.portalSiteId);
			const {hotels, areas, hotelTags, hotelTypes} = data;

			const noSelect = {
				id: '',
				type: 'noSelect',
				title: {
					ja: '指定しない',
					en: 'No Select',
					ko: '지정하지 않음',
					zh_CN: '不指定',
					zh_TW: '不指定',
				},
			};

			const largeAreas = areas.filter(area => !area.parentId);
			const areasTemp = await Promise.all(
				largeAreas.map(async area => {
					const childAreas = await Promise.all(
						data.areas
							.filter(child => child.parentId === area.id)
							.map(childArea => {
								const grandChildAreas = data.areas.filter(
									grandChild => grandChild.parentId === childArea.id,
								);
								if (grandChildAreas.length) {
									this.isThirdLayerSet = true;
								}
								grandChildAreas.unshift(noSelect);
								grandChildAreas.unshift(this.selectWholeArea(childArea.title));
								return {
									...childArea,
									childAreas: grandChildAreas,
								};
							}),
					);
					childAreas.unshift(noSelect);
					childAreas.unshift(this.selectWholeArea(area.title));
					return {
						...area,
						childAreas,
					};
				}),
			);

			this.portalHotels = hotels;
			this.portalHotels.unshift(noSelect);
			this.hotelTags = hotelTags;
			this.hotelTypes = hotelTypes;
			this.areas = areasTemp;
			this.areas.unshift(noSelect);
			this.areasAll = areas;
		},
		selectWholeArea(title) {
			// FOR MOBILE
			return {
				id: '',
				title: {
					ja: `${title.ja}全域を指定`,
					en: `Whole area of ${title.en}`,
					ko: `${title.ko || title.en}전역을 설정`,
					zh_CN: `${title.zh_CN || title.en}指定全部区域`,
					zh_TW: `${title.zh_TW || title.en}指定全部區域`,
				},
				type: 'selectAll',
			};
		},
		setDataForMock() {
			this.portalHotels = mockPortalHotels;
			this.hotelTypes = mockHotelTypes;
			this.hotelTags = mockHotelTags;
			this.roomTags = mockRoomTags;
			this.areas = mockAreas;
			this.areasAll = mockAreasAll;
		},
	},
};
</script>

<style lang="scss">
@import '@/sass/variables';
@import '@/styles/app';
</style>