export default {
  install(vue) {
    console.log("Installing Main plugin..");
    vue.mixin({
      data: () => ({
        dataReady: false,
      }),
      computed: {
        portalSiteId() {
          return this.$route.params.id;
        },
        portalDefaultSetting() {
          return this.$root.portalDefaultData;
        },
        viewLoading: {
          get() {
            return this.$root.viewLoading_;
          },
          set(val) {
            this.$root.viewLoading_ = val;
          },
        },
        rootDataReady: {
          get() {
            return this.$root.rootDataReady_;
          },
          set(val) {
            this.$root.rootDataReady_ = val;
          },
        },
        viewToken: {
          get() {
            let viewToken = localStorage.getItem("viewToken");
            if (!viewToken) return {};
            return JSON.parse(viewToken)[this.portalSiteId];
          },
        },
        langs() {
          return this.$root.langs_;
        },
        lang: {
          get() {
            return this.$root.lang_;
          },
          set(val) {
            this.$root.lang_ = val;
            document.documentElement.lang = val.split("_").join("-"); //追加
            localStorage.setItem("lang", val);
            document.title =
            this.portalDefaultSetting.title ? `RCBooking for Portal - ${this.getLabel(this.portalDefaultSetting.title)}` : "RCBooking for Portal";
          },
        },
        query: {
          get() {
            return this.$root.query_;
          },
          set(val) {
            this.$root.query_ = val;
          },
        },
      },
      methods: {
        getLabel(labelObject) {
          if (!labelObject) return null;
          if (!this.lang) return labelObject.ja;
          if (labelObject[this.lang]) {
            return labelObject[this.lang];
          } else if (labelObject.ja) {
            return labelObject.ja;
          } else {
            return labelObject.en;
          }
        },
      },
    });
  },
};
